import {useState, useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import {editApp} from '../../../redux/actions/AppActions'; 
import {updateApp} from '../../../redux/actions/AppActions'; 
import { APP_UPDATE_RESET } from "../../../redux/constants/AppConstants";
import { toast } from "react-toastify";
import {ToastObjects} from '../../../redux/actions/toastObject'; 
import { UserDropdown } from '../../../redux/actions/userActions';
import { useHistory } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import { FaApple, FaAndroid } from 'react-icons/fa'; 
import '../main.css'
import { Autocomplete, TextField } from '@mui/material';

const EditApp = ({match}) => {	
  const appId = match.params.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const appList = useSelector((state) => state.appList);
  const organization = useSelector((state)=>state?.organizationDetails?.organization);
  const userRole = organization?.user_role;
  const { apps } = appList; 
  const users = useSelector((state) => state.userDropdown.users);
  const appEdit = useSelector((state) => state.appEdit);
  const appUpdate = useSelector((state) => state.appUpdate);
  const role = useSelector((state) => state.userLogin.userInfo.data.role);
  const [submitted, setSubmitted] = useState(false);  
  const [isLogo, setIsLogo] = useState(false);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [formState,setFormState] = useState({
	values:{
		isIos: 0,
		isAndriod: 0,
		bundleName: '',
		packageName: ''
	}       
  });
  const [status, setStatus] = useState("");
  const organizationId = organization?.organization_id;
  const { app } = appEdit;
  const { success: successUpdate } = appUpdate;

  useEffect(() => {
  	setFormState({values:{}})
  	if (successUpdate) {
      dispatch({ type: APP_UPDATE_RESET });
      toast.success("App updated successfully", ToastObjects);
    } else {
      if (!app.name || app.id !== Number(appId)) {
        dispatch(editApp(appId));    
		dispatch(UserDropdown('app',appId, organizationId));    
      } else {
        setFormState({values:app})
		setSelectedUserIds(app.appAccess)
		setUserIds(app.appAccess)
      }
    }
  }, [app, dispatch, appId, organizationId]);
  

    const handleUserChange = (event) => {
	    const selectedUserId = parseInt(event.target.value);
	    if (!selectedUserIds.includes(selectedUserId)) {
	       setSelectedUserIds([...selectedUserIds, selectedUserId]);
	    }
    };

  const removeUser = (userIdToRemove) => {
	setSelectedUserIds(selectedUserIds.filter(userId => userId !== userIdToRemove));
  };

  const filteredOptions = users.filter(option => !selectedUserIds.includes(option.id));
  const selectedUsers = users.filter(option => selectedUserIds.includes(option.id));
  const handleChange = (event) => {
        setFormState(formState =>({
          ...formState,
          values:{
            ...formState.values,
            [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
          
        }));
      }

	  const handleUploadChange = (event) => {
        event.preventDefault();
        setLogo(event.target.files[0])
      };
	  const getValidationError = (name, value) => {
		switch (name) {
		  case 'logo':
			return !value ? 'Logo is required' : '';
		  case 'ios' || 'android':
			return !(formState.values.isIos || formState.values.isAndriod) ? 'At least one platform must be selected' : '';
		  default:
			return '';
		}
	  };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true); 
        const { name, description, isAndriod, isIos, packageName, bundleName } = formState.values;
		const removeIds = userIds.filter((element) => !selectedUserIds.includes(element));

        if (name && description) {
			if ((isIos || isAndriod)) {
				let _errorFlag = false, _errorMessage = "";
				if(isAndriod === 1 && packageName.length === 0 && isIos === 0){
					_errorFlag = true;
					_errorMessage = "Please enter App package name.";
				} else if(isIos === 1 && bundleName.length === 0 && isAndriod === 0){
					_errorFlag = true;
					_errorMessage = "Please enter App Bundle name.";
				} else if((isAndriod === 1 && isIos === 1)){
					
					if(bundleName.length === 0 && packageName.length === 0){
						_errorFlag = true;
					  _errorMessage = "Please enter App Bundle name and App package name.";
					} else if(packageName.length === 0){
						_errorFlag = true;
                      _errorMessage = "Please enter App package name.";
					} else if(bundleName.length === 0){
						_errorFlag = true;
                      _errorMessage = "Please enter App Bundle name.";
					}
				}

				if(_errorFlag === false){
					console.log('Form is valid');
					setLoading(true)
					let payload = new FormData();
	
					payload.set("id", appId);
					payload.set("name", name);
					payload.set("description", description);
					payload.set("isAndriod", isAndriod);
					payload.set("isIos", isIos);
					payload.set("removeIds", removeIds);
					payload.set("selectedUserIds", selectedUserIds);
					payload.set("packageName", packageName);
					payload.set("bundleName", bundleName);
	
					if(logo){
					  payload.set("oldLogoId", app.logo_id);
					  payload.append("logo", logo);
					}
	
					if(app.app_status === 'Requested'){
						payload.set("app_status", status);
					}
					
					dispatch(updateApp(payload)).then((res)=>{
						setSubmitted(false);
						setFormState(formState => ({
							...formState,
							errors: {
							  ...formState.errors,
							  ios: getValidationError('ios', formState.values.ios),
							  android: getValidationError('android', formState.values.android),
							}
						  }));
						setLoading(false)
						navigateToAppList();
					});
				} else {
                   alert(_errorMessage);
				}
			} else {
				console.log('Form has errors');
				alert("At least one os will be selected")
			}
        }
    }

	const navigateToAppList = () => {
		history.push('/apps');
	};
	
	const handleStatus = (e) => {
		setStatus(e.target.value)
	}

	const appStatuses = apps.map((app) => app.app_status);
    const isAnyRequested = appStatuses.some(status => status === 'Active');

	return(
		<>
		    <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">

										{isAnyRequested ?
										<>
										<div className="header headingWrap">
										<Link to="/apps" className ="backarrowcolor">
											<i class="bi bi-arrow-left-circle-fill backIcon"></i>
										</Link>
										<h4 className="card-title">Edit App Details</h4>
										</div>	
										<form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">App Name
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
					                                        name="name"  
					                                        onChange={handleChange}
					                                        value={formState.values.name || ''}
					                                        />
					                                        {submitted && !formState.values.name &&
					                                            <div className="inline-errormsg">App name is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>

											  <div className="col-md-6">
											  	<div className="form-group">
				                                    <label className="col-form-label mb-0">App Logo
													<span style={{ color: 'red' }}>*</span>
													</label>

													{formState.values.logo_url && isLogo === false ? (
                                                        <div className="">
															<img src={formState.values.logo_url} style={{height:"80px", width:"80px", paddingRight:"20px"}}></img>
                                                            {/* <a href={formState.values.logo_url} download={getFileNameFromUrl(formState.values.logo_url)} className="btn btn-link">
                                                               {getFileNameFromUrl(formState.values.logo_url)} <i className="fa fa-download  pl-10"></i>
                                                            </a> */}

													        <button className="btn btn-secondary me-1" onClick={()=> setIsLogo(true)}>Change Logo</button>
                                                        </div>
											        )
											        :
											
				                                    <div className="col-sm-9">
				                                       <input type="file" className={'form-control form-control-lg' + (submitted && !logo ? ' is-invalid' : '')} 
					                                        name="file" 
                                                            single
                                                            accept="image/*"
					                                        onChange={handleUploadChange}
					                                        />
					                                        {submitted && !logo &&
					                                            <div className="inline-errormsg">Logo is required</div>
					                                        }
				                                    </div>
}
				                                 </div>
				                              </div>

											  <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Description
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
				                                       <textarea rows={5} cols={5} className={'form-control form-control-lg' + (submitted && !formState.values.title ? ' is-invalid' : '')} 
					                                        name="description" 
					                                        onChange={handleChange}
					                                        value={formState.values.description || ''}
					                                        />
					                                        {submitted && !formState.values.description &&
					                                            <div className="inline-errormsg">Description is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>

											  <div className="col-md-6">
											  <div className="form-group">
                                                <label className="col-form-label mb-0"> OS 
												  <span style={{ color: 'red' }}>*</span>
                                                </label>
												<div className="" style={{"paddingLeft":"26px"}}>
												   <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="isIos"
                                                          onChange={handleChange}
                                                          checked={formState.values.isIos}
                                                          style={{ marginRight: '10px' }}
                                                        />
                                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '35px', marginTop: '10px' }}>
                                                           <FaApple style={{ marginRight: '5px' }} /> iOS
                                                        </label>
                                                        {formState.values.isIos === 1 && (
                                                            <input
                                                               type="text"
                                                               className="form-control"
															   name="bundleName"
                                                               placeholder="Enter bundle name"
                                                               value={formState.values.bundleName}
                                                               onChange={handleChange}
                                                               style={{ marginLeft: '10px' }}
                                                            />
                                                        )}
                                                    </div>
	                                                <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                           type="checkbox"
                                                           className="form-check-input"
                                                           name="isAndriod"
                                                           onChange={handleChange}
                                                           checked={formState.values.isAndriod}
                                                           style={{ marginRight: '10px' }}
                                                        />
                                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginTop: '10px' }}>
                                                            <FaAndroid style={{ marginRight: '5px' }} /> Android
                                                        </label>
                                                        {formState.values.isAndriod === 1 && (
                                                          <input
                                                            type="text"
                                                            className="form-control"
															name="packageName"
                                                            placeholder="Enter package name"
                                                            value={formState.values.packageName}
                                                            onChange={handleChange}
                                                            style={{ marginLeft: '10px' }}
                                                          />
                                                        )}
                                                    </div>
                                                    {submitted && !(formState.values.isIos || formState.values.isAndriod) && (
                                                        <div className="invalid-feedback d-block">At least one platform must be selected</div>
                                                    )}
                                                </div>
                                             </div>
				                              </div>

											  	<div className="col-md-12">
	                                                <div className="form-group">
                                                        <label className="col-form-label">Access To </label>
                                                        <div className="">
															<div className="custom-select-container">
															<div className="selected-roles">
                                                                {selectedUsers.map((user) => (
                                                                    <span key={user.id} className="selected-role">
                                                                      {user.name}
                                                                      <button type="button" className="remove-role-btn" onClick={() => removeUser(user.id)}>x</button>
                                                                    </span>
                                                                ))}

                                                               <select className="form-control role-select" onChange={handleUserChange} value="">
                                                                    <option value="" disabled>Select User</option>
                                                                    {filteredOptions.map((user) => (
                                                                       <option key={user.id} value={user.id}>{user.name}</option>
                                                                    ))}
                                                                </select> 
																{/* <Autocomplete
																className='SelectAccessTo'
                                                                   options={filteredOptions}
                                                                   getOptionLabel={(option) => option.name}
                                                                   onChange={handleUserChange}
                                                                   renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                                                   isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                   value={null}
                                                                   /> */}
                                                            </div>
															</div>
	                                                       
                                                        </div>
                                                    </div>
													
                                                </div>

				                           </div>

											{userRole === 'Admin' ?
											  <div className="row">
				                              	<div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Status
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
														<select className="form-select form-control" aria-label="Envirionment" value={status} onChange={handleStatus}>
															<option value="">Select Status</option>
															{app.app_status === 'Requested'?
															<>
														  	  <option value="Active">Approve</option>
															  <option value="Rejected">Reject</option>
															</>
															
															: app.app_status === 'Active' ?
															<>
														  	  <option value="Inactive">Inactive</option>
															</>
															: app.app_status === 'Inactive' ?
															<>
														  	  <option value="Active">Active</option>
															</>
															: null }
														</select>
				                                    </div>
				                                 </div>
				                              </div>				                              
				                              </div>
											: null}
				                            <div className="text-left">
				                            	<button type="submit" className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
                    							<Link to="/apps"><button className="ButtonLight col-sm-2 col-form-label text-center ml-2">Cancel</button></Link>
                    						</div>
				                        </form>
										</>:
										<><div className="header headingWrap ">
										<Link to="/apps" className ="backarrowcolor">
										<i class="bi bi-arrow-left-circle-fill backIcon"></i>
										</Link>
										<h4 className="card-title">App Details</h4>
										</div>
										<form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">App Name
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
					                                        name="name"  
															readOnly                              
					                                        onChange={handleChange}
					                                        value={formState.values.name || ''}
					                                        />
					                                        {submitted && !formState.values.name &&
					                                            <div className="inline-errormsg">App name is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>

											  <div className="col-md-6">
													<div className="form-group">
														<label className="col-form-label mb-0">App Logo
														<span style={{ color: 'red' }}>*</span>
														</label>

														{formState.values.logo_url && isLogo === false ? (
															<div className="">
																<img src={formState.values.logo_url} style={{height:"80px", width:"80px", paddingRight:"20px"}}></img>
																{/* <a href={formState.values.logo_url} download={getFileNameFromUrl(formState.values.logo_url)} className="btn btn-link">
																{getFileNameFromUrl(formState.values.logo_url)} <i className="fa fa-download  pl-10"></i>
																</a> */}

																<button className="btn btn-secondary me-1" disabled onClick={()=> setIsLogo(true)}>Change Logo</button>
															</div>
														)
														:
												
														<div className="col-sm-9">
														<input type="file" className={'form-control form-control-lg' + (submitted && !logo ? ' is-invalid' : '')} 
																name="file" 
																readOnly
																single
																accept="image/*"
																onChange={handleUploadChange}
																/>
																{submitted && !logo &&
																	<div className="inline-errormsg">Logo is required</div>
																}
														</div>
	}
													</div>
												</div>

											  <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Description
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
				                                       <textarea rows={5} cols={5} className={'form-control form-control-lg' + (submitted && !formState.values.title ? ' is-invalid' : '')} 
					                                        name="description" 
															readOnly                         
					                                        onChange={handleChange}
					                                        value={formState.values.description || ''}
					                                        />
					                                        {submitted && !formState.values.description &&
					                                            <div className="inline-errormsg">Description is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>	

												<div className="col-md-6">
													<div className="form-group">
													<label className="col-form-label"> OS 
													<span style={{ color: 'red' }}>*</span>
													</label>
													<div className="" style={{"paddingLeft":"26px"}}>
													<div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
															<input
															type="checkbox"
															className="form-check-input"
															name="isIos"
															onChange={handleChange}
															checked={formState.values.isIos}
															style={{ marginRight: '10px' }}
															/>
															<label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '35px', marginTop: '10px' }}>
															<FaApple style={{ marginRight: '5px' }} /> iOS
															</label>
															{formState.values.isIos === 1 && (
																<input
																type="text"
																readOnly
																className="form-control"
																name="bundleName"
																placeholder="Enter bundle name"
																value={formState.values.bundleName}
																onChange={handleChange}
																style={{ marginLeft: '10px' }}
																/>
															)}
														</div>
														<div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
															<input
															type="checkbox"
															readOnly
															className="form-check-input"
															name="isAndriod"
															onChange={handleChange}
															checked={formState.values.isAndriod}
															style={{ marginRight: '10px' }}
															/>
															<label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginTop: '10px' }}>
																<FaAndroid style={{ marginRight: '5px' }} /> Android
															</label>
															{formState.values.isAndriod === 1 && (
															<input
																type="text"
																readOnly
																className="form-control"
																name="packageName"
																placeholder="Enter package name"
																value={formState.values.packageName}
																onChange={handleChange}
																style={{ marginLeft: '10px' }}
															/>
															)}
														</div>
														{submitted && !(formState.values.isIos || formState.values.isAndriod) && (
															<div className="invalid-feedback d-block">At least one platform must be selected</div>
														)}
													</div>
													</div>
												</div>	

												

												<div className="col-md-12">
	                                                <div className="form-group">
                                                        <label className="col-form-label">Access To </label>
                                                        <div className="" >
															<div className="custom-select-container">
															<div className="selected-roles">
                                                                {selectedUsers.map((user) => (
                                                                    <span key={user.id} className="selected-role">
                                                                      {user.name}
                                                                      <button type="button" className="remove-role-btn" onClick={() => removeUser(user.id)}>x</button>
                                                                    </span>
                                                                ))}

                                                                 <select className="form-control role-select" disabled onChange={handleUserChange} value="">
                                                                    <option value="" disabled>Select User</option>
                                                                    {filteredOptions.map((user) => (
                                                                       <option key={user.id} value={user.id}>{user.name}</option>
                                                                    ))}
                                                                </select>
																{/* <Autocomplete
																   readOnly
                                                                   options={filteredOptions}
                                                                   getOptionLabel={(option) => option.name}
                                                                   onChange={handleUserChange}
                                                                   renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                                                   isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                   value={null}
                                                                   /> */}
                                                            </div>
															</div>
	                                                       
                                                        </div>
                                                    </div>
													
                                                </div>
				                           </div>


											{userRole === 'Admin' ?
											  <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Status
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
														<select className="form-select form-control" aria-label="Envirionment" value={status} onChange={handleStatus}>
															<option value="">Select Status</option>
															{app.app_status === 'Requested'?
															<>
														  	  <option value="Active">Approve</option>
															  <option value="Rejected">Reject</option>
															</>
															
															: app.app_status === 'Active' ?
															<>
														  	  <option value="Inactive">Inactive</option>
															</>
															: app.app_status === 'Inactive' ?
															<>
														  	  <option value="Active">Active</option>
															</>
															: null }
														</select>
				                                    </div>
				                                 </div>
				                              </div>				                              
				                              </div>
											: null}

										
				                            <div className="text-left">
				                            	<button type="submit" className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
                    							<Link to="/apps"><button className="ButtonLight col-sm-2 col-form-label text-center ml-2">Cancel</button></Link>
                    						</div>
				                        </form>
										</>
										}
				                     </div>
				                  </div>
				               </div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
			{loading &&  <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>}
		</>
		)
}

export default EditApp;