import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";


function PrivateRouter({ component: Component, ...rest }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  if(typeof userInfo !== 'undefined' && userInfo !== null){
  	const token = userInfo.token;
	  if(typeof token != undefined && token){
	   	axios.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;//send token
	  }
  }

  let isAdmin = 2;
  if (userInfo){
    isAdmin = (userInfo.data.isAdmin === true) ? 1 : 0;
  }  

  return (
    <Route
      {...rest}
      component={(props) => {
        if (isAdmin === 1 || isAdmin === 0) {
          //If page is on home page with only / then redirect to dashboard route
          return (props.location.pathname === '/') ? 
            <Redirect to={`/apps`} /> :
            <Component {...props} />;
        } else {
          return <Redirect to={`/landing`} />;
        }
      }}
    />
  );
}

export default PrivateRouter;
