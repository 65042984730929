import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateorganizationStatus, getOrganization } from '../../../redux/actions/OrganizationAction';
import { Circles } from 'react-loader-spinner'; 

const OrganizationInfo = (props) => {
    const [loading, setLoading] = useState(false);
   const dispatch = useDispatch();
   const organization = useSelector((state) => state?.organizationEdit?.organization);

   const handleToggleChange = () => {
    if(organization?.isActive === 1){
        if (window.confirm(`Want to Deactivate ${organization?.name} ?`)) {
            let reqData = {
                id: organization?.id,
                isActive: false
            };

            setLoading(true);
			dispatch(updateorganizationStatus(reqData)).then((response)=>{
                setLoading(false);
                dispatch(getOrganization(organization?.id))
            })
		}
    } else  if(organization?.isActive === 0){
        if (window.confirm(`Want to Activate ${organization?.name} ?`)) {
            let reqData = {
                id: organization?.id,
                isActive: true
            };
            
            setLoading(true);
			dispatch(updateorganizationStatus(reqData)).then((response)=>{
                setLoading(false);
                dispatch(getOrganization(organization?.id))
            })
		}
    }
  };

    return(
		<>
            <div className="card">
				<div className="card-body">
					<form className="form-sample">
				        <p className="card-description"></p>
				            <div className="row">
				                <div className="col-md-6">
				                    <div className="form-group row">
										<h4 className="col-sm-3 col-form-label">Name :</h4>
										<div className="col-sm-9">
											<h4 className="col col-form-label">{organization?.name}</h4>
										</div>
				                    </div>
				                </div>				                              
				            {/* </div>

                            <div className="row"> */}
				                <div className="col-md-6">
				                    <div className="form-group row">
										<h4 className="col-sm-3 col-form-label">Email :</h4>
										<div className="col-sm-9">
											<h4 className="col col-form-label">{organization?.email}</h4>
										</div>
									</div>
				                </div>				                              
				            {/* </div>

                            <div className="row"> */}
				                <div className="col-md-6">
				                    <div className="form-group row">
										<h4 className="col-sm-3 col-form-label">Phone :</h4>
										<div className="col-sm-9">
											<h4 className="col col-form-label">{organization?.phone}</h4>
										</div>
									</div>
				                </div>				                              
				            {/* </div>

                            <div className="row"> */}
				                <div className="col-md-6">
				                    <div className="form-group row">
										<h4 className="col-sm-3 col-form-label">Address :</h4>
										<div className="col-sm-9">
											<h4 className="col col-form-label">{organization?.address}</h4>
										</div>
									</div>
				                </div>	

                                <div className="col-md-6">
				                    <div className="form-group row">
										<h4 className="col-sm-3 col-form-label">Created By :</h4>
										<div className="col-sm-9">
											<h4 className="col col-form-label">{organization?.createdBy}</h4>
										</div>
									</div>
				                </div>		

                                <div className="col-md-6">
				                    <div className="form-group row">
										<h4 className="col-sm-3 col-form-label">Active :</h4>
										<div className="col-sm-9" style={{ paddingTop: "10px"}}>
                                        <label className="switch">
                                                      <input
                                                         type="checkbox"
                                                         checked={organization.isActive}
                                                         onChange={handleToggleChange}
                                                      />
                                                      <span className="slider round"></span>
                                                   </label>
										</div>
									</div>
				                </div>			                              
				            </div>            
				    </form>
				</div>
			</div>
            {loading &&  <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>}
        </>
    )
}


export default OrganizationInfo;