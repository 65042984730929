import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import {createApp} from '../../../redux/actions/AppActions'; 
import { UserDropdown } from '../../../redux/actions/userActions';
import { useHistory } from 'react-router-dom';
import { FaApple, FaAndroid } from 'react-icons/fa';
import { Circles } from 'react-loader-spinner'; 
import '../main.css'
import { toast } from "react-toastify";

const AddApp = () => {
    const history = useHistory();
	const dispatch = useDispatch();
	const organization = useSelector((state)=>state?.organizationDetails?.organization);
	const userRole = organization?.user_role;
	const users = useSelector((state) => state.userDropdown.users);
	const role = useSelector((state) => state.userLogin.userInfo.data.role);
    const [submitted, setSubmitted] = useState(false);  
	const [loading, setLoading] = useState(false); 
    const [logo, setLogo] = useState(null)
	const [logoError, setLogoError] = useState(null)
	// const [selectedUserIds, setSelectedUserIds] = useState([]);
	const {userLogin: { userInfo :{data} }} = useSelector((state) => state);  
	const organizationId = organization?.organization_id;
	const [selectedUserIds, setSelectedUserIds] = useState(() => {
        const defaultUser = users.find(user => user.name === data.name);
        return defaultUser ? [defaultUser.id] : [];
    });
	const [formState,setFormState] = useState({
        values:{
			ios: 0,
			android: 0,
			packageName: '',
			bundleName:''
		},
		errors: {}       
    });

	useEffect(() => {
    	dispatch(UserDropdown('app','', organizationId));
  	}, [organizationId]);

	const handleUserChange = (event) => {
	  const selectedUserId = parseInt(event.target.value);
	  if (!selectedUserIds.includes(selectedUserId)) {
		setSelectedUserIds([...selectedUserIds, selectedUserId]);
	  }
	};
  
	const removeUser = (userIdToRemove) => {
	  setSelectedUserIds(selectedUserIds?.filter(userId => userId !== userIdToRemove));
	};
  
	const filteredOptions = users?.filter(option => !selectedUserIds.includes(option.id));
	const selectedUsers = users?.filter(option => selectedUserIds.includes(option.id));

	const handleUploadChange = (event) => {
        event.preventDefault();
     
		let file = event.target.files[0];
		if (file) {
			if (file.type.startsWith('image/')) {
			  const img = new Image();
			  img.onload = () => {
				if (img.width === img.height) {
					if(img.width < 256 || img.height < 256){
						setLogoError('The logo image width and height must be more than equal to 256 x 256 pixel.');
					} else {
						setLogo(file);
						setLogoError('')
					}
				} else {
				  setLogo(null);
				  setLogoError('The logo must be a square image (width and height must be equal).');
				}
			  };
			  img.onerror = () => {
				setLogo(null);
				setLogoError('Invalid image file.');
			  };
			  img.src = URL.createObjectURL(file);
			} else {
			 setLogo(null);
			 setLogoError('Invalid file type. Only image files are allowed.');
			}
		  } else {
			setLogo(null);
			setLogoError('');
		  }
    };

    const handleChange = (event) => {
        setFormState(formState =>({
          ...formState,
          values:{
            ...formState.values,
            [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          },
		  errors: {
			...formState.errors,
			[event.target.name]: getValidationError(event.target.name, event.target.value)
		  }
          
        }));
    }

	const getValidationError = (name, value) => {
		switch (name) {
		  case 'logo':
			return !value ? 'Logo is required' : '';
		  case 'ios' || 'android':
			return !(formState.values.ios || formState.values.android) ? 'At least one platform must be selected' : '';
		  default:
			return '';
		}
	};

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true); 
        const { name, description, android, ios, packageName, bundleName } = formState.values;

        if (name && description && logo && bundleName || packageName) {
			if ((formState.values.ios || formState.values.android)) {
				let _errorFlag = false, _errorMessage = "";
				if(android === true && packageName.length === 0 && ios === false){
					_errorFlag = true;
					_errorMessage = "Please enter App package name.";
				} else if(ios === true && bundleName.length === 0 && android === false){
					_errorFlag = true;
					_errorMessage = "Please enter App Bundle name.";
				} else if((android === true && ios === true)){
					if(bundleName.length === 0 && packageName.length === 0){
						_errorFlag = true;
					    _errorMessage = "Please enter App Bundle name and App package name.";
					} else if(packageName.length === 0){
						_errorFlag = true;
                        _errorMessage = "Please enter App package name.";
					} else if(bundleName.length === 0){
						_errorFlag = true;
                        _errorMessage = "Please enter App Bundle name.";
					}
				}

				if(_errorFlag === false){
					console.log('Form is valid');

					setLoading(true)
					let payload = new FormData();
					payload.set("name", name);
					payload.set("description", description);
					payload.set("isAndriod", android);
					payload.set("isIos", ios);
					payload.set("selectedUserIds", selectedUserIds);
					payload.set("packageName", packageName);
					payload.set("bundleName", bundleName);
					payload.set("organization_id", organizationId)
					
					if(userRole === 'Member' || userRole === 'Tester'){
						payload.set("app_status", "Requested")
					} else if(role === 'Super_Admin') {
						payload.set("app_status", "Active")
					}
					
					payload.append("logo", logo);
					dispatch(createApp(payload)).then((res)=>{
						setSubmitted(false);
						setFormState(formState => ({
							...formState,
							errors: {
							  ...formState.errors,
							  ios: getValidationError('ios', formState.values.ios),
							  android: getValidationError('android', formState.values.android),
							}
						}));
						setLoading(false)
						navigateToAppList();
					});
				} else {
                   toast.error(_errorMessage);
				}
				
			} else {
				toast.error("At least one OS will be selected")
			}
        } else if(!formState.values.name && !formState.values.description && !logo){
			toast.error("Fill all Details")
		}
		else if(!formState.values.name) {
			toast.error("Enetr Name")
		}else if(!formState.values.description){
			toast.error("Enter Description")
		}else if(!logo){
			toast.error("Upload Logo")
		}
    }

	const navigateToAppList = () => {
		history.push('/apps');
	};

	return(
		<>
		    <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">
				                        {/* <h4 className="card-title">
                                            {role === 'Member' ? `Request App` : `Add App`}
										</h4> */}
										<div className="header headingWrap">
                                         {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
										 <Link to="/apps" className ="backarrowcolor">
										 	<i class="bi bi-arrow-left-circle-fill backIcon"></i>
                                          </Link>
                                         <h4 className="card-title">{userRole === 'Member' || userRole === 'Tester' ? `Request App` : `Add App`}</h4>
                                         </div>	
				                        <form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">App Name
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input 
															type="text" 
															className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
					                                        name="name"                                
					                                        onChange={handleChange}
					                                        value={formState.values.name || ''}
					                                        />
					                                        {submitted && !formState.values.name &&
					                                            <div className="inline-errormsg">App name is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>	
											  <div className="col-md-6">
											  	<div className="form-group">
				                                    <label className="col-form-label">App Logo
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
				                                       <input type="file" className={'form-control form-control-lg' + (submitted && !logo ? ' is-invalid' : '')} 
					                                        name="file" 
                                                            single
                                                            accept="image/*"
					                                        onChange={handleUploadChange}
					                                        />
					                                        {submitted && !logo &&
					                                            <div className="inline-errormsg">Logo is required</div>
					                                        }
															
															{logoError?.length > 0 ?
															   <div className="inline-errormsg">{logoError}</div>
															: null}
				                                    </div>
				                                 </div>
				                              </div>
											  <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Description
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
				                                       <textarea rows={5} cols={5} 
													       type="text"
													       className={'form-control form-control-lg' + (submitted && !formState.values.description ? 'is-invalid' : '')} 
					                                        name="description"                          
					                                        onChange={handleChange}
					                                        value={formState.values.description || ''}
					                                        />
					                                        {submitted && !formState.values.description &&
					                                            <div className="inline-errormsg">Description is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>	

											  <div className="col-md-6">
											  <div className="form-group">
                                                <label className="col-form-label mb-0"> OS 
												  <span style={{ color: 'red' }}>*</span>
                                                </label>
												<div className="" style={{"paddingLeft":"26px"}}>
												   <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="ios"
                                                          onChange={handleChange}
                                                          checked={formState.values.ios}
                                                          style={{ marginRight: '10px' }}
                                                        />
                                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '35px', marginTop: '10px' }}>
                                                           <FaApple style={{ marginRight: '5px' }} /> iOS
                                                        </label>
                                                        {formState.values.ios === true && (
                                                            <input
                                                               type="text"
                                                               className="form-control"
															   name="bundleName"
                                                               placeholder="Enter bundle name"
                                                               value={formState.values.bundleName}
                                                               onChange={handleChange}
                                                               style={{ marginLeft: '10px' }}
                                                            />
                                                        )}
                                                    </div>
	                                                <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                           type="checkbox"
                                                           className="form-check-input"
                                                           name="android"
                                                           onChange={handleChange}
                                                           checked={formState.values.android}
                                                           style={{ marginRight: '10px' }}
                                                        />
                                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginTop: '10px' }}>
                                                            <FaAndroid style={{ marginRight: '5px' }} /> Android
                                                        </label>
                                                        {formState.values.android === true && (
                                                          <input
                                                            type="text"
                                                            className="form-control"
															name="packageName"
                                                            placeholder="Enter package name"
                                                            value={formState.values.packageName}
                                                            onChange={handleChange}
                                                            style={{ marginLeft: '10px' }}
                                                          />
                                                        )}
                                                    </div>
                                                    {submitted && !(formState.values.ios || formState.values.android) && (
                                                        <div className="invalid-feedback d-block">At least one platform must be selected</div>
                                                    )}
                                                </div>
                                             </div>
				                              </div>

											  <div className="col-md-6">
	                                                <div className="form-group">
                                                        <label className="col-form-label">Access To</label>
                                                        <div className="">
	                                                        <div className="custom-select-container">
															<div className="selected-roles">
															
                                                                {selectedUsers.map((user) => (
                                                                    <span key={user.id} className="selected-role">
                                                                       {user.name}
                                                                       <button type="button" className="remove-role-btn" onClick={() => removeUser(user.id)}>x</button>
                                                                    </span>
                                                                ))}
                                                                <select className="form-control role-select" onChange={handleUserChange} value="">
                                                                    <option value="" disabled>Select User</option>
                                                                    {filteredOptions.map((user) => (
                                                                        <option key={user.id} value={user.id}>{user.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
															</div>
															
                                                        </div>
                                                    </div>
                                              </div>			                              
				                           </div>

				                         
				                            <div className="text-left">
				                            	<button type="submit" className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
                    							<Link to="/apps"><button className="ButtonLight col-sm-2 col-form-label text-center ml-2">Cancel</button></Link>
                    						</div>
				                        </form>
				                     </div>
				                  </div>
				               </div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
			{loading &&  <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>}
		</>
		)
}

export default AddApp;